/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';

import { StyleSheet, css } from 'aphrodite';
import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import MediaResourceGroup from 'components/Resources/MediaResourceGroup';
import i18n from 'utils/i18n';
import SubNav from '../../components/subNav';

const styles = StyleSheet.create({
  title: {
    fontSize: '2em'
  },
  sectionHeadline: {
    fontSize: '1.6em'
  },
  sectionSubhead: {
    fontSize: '1.2em',
    marginTop: 20,
    marginBottom: 10
  },
  markdownContent: {
    fontSize: '1em',
    lineHeight: '1.5em',
    ':nth-child(1n) > p,ul': {
      marginTop: 0
    },
    ':nth-child(1n) > ul': {
      listStyleType: 'none',
      marginLeft: -40
    }
  }
});

class ResourcesIndexPage extends React.Component {
  componentDidMount() {
    const {
      pageContext: { locale }
    } = this.props;
    if (locale === 'en') {
      navigate('https://www.lumoslabs.com/press');
    }
  }

  mediaResources() {
    const {
      data: {
        allContentfulMediaResourceList: { edges: mediaResourceGroups }
      }
    } = this.props;

    return mediaResourceGroups.map(({ node: { groupingName, mediaResources } }) => (
      <MediaResourceGroup key={groupingName} name={groupingName} resources={mediaResources} />
    ));
  }

  pressInfo() {
    const {
      data: {
        allContentfulPressInfo: { edges: pressInfoChunks }
      }
    } = this.props;

    return pressInfoChunks.map(({ node: { header, text } }) => (
      <div key={header} className={css(styles.markdownContent)}>
        <div className={css(styles.sectionSubhead)}>{header}</div>
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
          className={css(styles.markdownContent)}
        />
      </div>
    ));
  }

  render() {
    const {
      location,
      pageContext: { locale, pagePath }
    } = this.props;
    return (
      <Layout locale={locale} location={location}>
        <Metatags
          title={i18n.t('ResourcesPage.title')}
          description={i18n.t('ResourcesPage.description')}
          thumbnail="https://asset.lumosity.com/resources/static/press/mobile/ios.png"
          imageWidth="820"
          imageHeight="615"
          canonical={pagePath}
          locale={locale}
        />
        <div>
          <SubNav locale={locale} />
          <Container>
            <h1 className={css(styles.title)}>{i18n.t('ResourcesPage.press_heading')}</h1>
            <Row>
              <Col xs="12" md="8">
                {this.mediaResources()}
              </Col>
              <Col>
                <h3 className={css(styles.sectionHeadline)}>{i18n.t('ResourcesPage.press_info_heading')}</h3>
                {this.pressInfo()}
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

ResourcesIndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string
  }).isRequired,
  data: PropTypes.shape({
    allContentfulPressInfo: PropTypes.shape({
      edges: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export default ResourcesIndexPage;

export const pageQuery = graphql`
  query AllMediaResourceGroups($locale: String!) {
    allContentfulMediaResourceList(filter: { node_locale: { eq: $locale } }, sort: { fields: [priority] }) {
      edges {
        node {
          groupingName
          node_locale
          mediaResources {
            caption
            thumbnail {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
              }
            }
            asset {
              file {
                url
              }
            }
            assetType
          }
        }
      }
    }
    allContentfulPressInfo(filter: { node_locale: { eq: $locale } }, sort: { fields: [priority] }) {
      edges {
        node {
          header
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
