import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import Img from "gatsby-image";

import * as layout from '../../styles/helpers/layout';

const styles = StyleSheet.create({
  wrapper: {
    [layout.mediaQuery.maxWidth.desktopSmall]: {
      width: '180px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
    },
    width: '250px',
    fontFamily: 'Museo Sans',
    marginBottom: '75px'
  },
  thumbnail: {
    [layout.mediaQuery.maxWidth.desktopSmall]: {
      width: '180px',
    },
    '@media (max-width: 420px)': {
      minWidth: '100%',
    },
    width: '250px',
    border: '1px solid #e2e2e2',
  },
  name: {
    float: 'left'
  },
  format: {
    float: 'right',
    color: '#08525b'
  }
})

export default ({ thumbnail, imageUrl, caption, assetType }) => (
  <div className={css(styles.wrapper)}>
    <a href={imageUrl}>
      <Img fluid={thumbnail.fluid} className={css(styles.thumbnail)} />
    </a>
    <div className={css(styles.name)}>{caption}</div>
    <div className={css(styles.format)}><a href={imageUrl}>{assetType}</a></div>
  </div>
)
