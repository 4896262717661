import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { StyleSheet, css } from 'aphrodite'

import MediaResource from './MediaResource'

const styles = StyleSheet.create({
  sectionHeadline: {
    fontSize: '1.6em',
  }
});

function MediaResourceGroup({ name, resources }) {
  const mediaResources = resources.map(({ caption, assetType, thumbnail, asset }) => (
    <Col key={caption}>
      <MediaResource
        thumbnail={thumbnail}
        imageUrl={asset.file.url}
        caption={caption}
        assetType={assetType}
      />
    </Col>
  ));
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h3 className={css(styles.sectionHeadline)}>{name}</h3>
          </Col>
        </Row>
        <Row>
          {mediaResources}
        </Row>
      </Col>
    </Row>
  )
}

MediaResourceGroup.propTypes = {
  name: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      assetType: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        })
      }),
      asset: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        })
      })
    })
  )
}

export default MediaResourceGroup;
